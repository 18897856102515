.scroll-to-top-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  bottom: 130px;
  right: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  /* border: 1px solid rgb(180, 180, 180); */
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18);
}
.scroll-to-top-icon {
  width: 30px;
  height: 30px;
  color: #363686;
}

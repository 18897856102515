.header-container-wrapper {
  position: sticky;
  z-index: 9998;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* min-width:1920px; */
  width: 100%;
  min-width: 1200px;
  /* border:4px solid red; */
  box-sizing: border-box;
  height: 80px;
  /* padding: 0px 290px; */
  background-color: rgba(240, 240, 240, 0.89);
}
/* @media (max-width: 1500px) {
  .header-container-wrapper {
    padding: 0px 150px;
  }
} */
.header-container {
  position: sticky;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 1200px;

  box-sizing: border-box;
  height: 80px;

  margin: 0px auto;
}

.logo {
  width: 150px;
}
.menu-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menu {
  cursor: pointer;
  height: 100%;
  font-size: 17px;
  height: 56px;
  font-weight: 400;
  padding: 20px 10px 10px;
  box-sizing: border-box;
  margin-right: 25px;
}
.menu:last-child {
  margin: 0px;
}
.clicked {
  /* border-bottom:2px solid #363686;; */
}

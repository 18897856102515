.footer-container {
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0px auto;
  padding: 30px 0px;
  border-top: 1px solid lightgray;
  box-sizing: border-box;
  background-color: white;
  font-size: 50px;
  font-size: 12px;
  color: #999999;
  font-weight: bold;
}
.footer-description {
  margin-left: 28px;
  width: 450px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.8;
}
.footer-copyright {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -1px;
}
.footer-logo-box {
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-logo {
  width: 53px;
  margin-right: 5px;
}

.main-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border:4px solid red; */
  box-sizing: border-box;
}
.main-page-section {
  width: 1200px;
  margin: 0 auto 200px;
  /* min-height: 700px; */
  padding: 40px 120px 90px;
  /* border:4px solid blue; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  background-color: rgb(252, 252, 252, 0.9);
}
.asd {
  color: rgb(54, 54, 134);
}
@keyframes fadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes productsFadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-page-menu-content-product-container {
  animation: productsFadeOut 2s backwards;
  margin-right: 20px;
}
.company-customer-country-container-main-page {
  bottom: 300px;
  left: -20px;
  position: relative;
  /* width: 710px; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.75);
}
.company-customer-company-logo-mobase-mainpage {
  position: relative;
  left: -275px;
  top: 80px;
  width: 120px;
}
.company-customer-world-pin-line-suwon-mainpage {
  transform: rotate(0deg);
  position: relative;
  top: 40px;
  right: 130px;
  width: 150px;
  height: 2px;
  border-top: 2px dashed gray;
  margin-bottom: 25px;
  margin-top: 25px;
}
.company-customer-country-map-korea-main-page {
  margin-top: 290px;
  position: relative;
  width: 350px;
}
.company-customer-country-container-main-page-2 {
  bottom: 85px;
  left: -20px;
  position: relative;
  /* width: 710px; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.7);
}

.main-page-menu-content-layout {
  min-width: 100px;
  display: flex;
}
.main-page-banner-container {
  position: relative;
  width: 1200px;
  margin: 0px auto;
  /* padding:0px 285px; */
  box-sizing: border-box;
  border-bottom: 1px solid lightgray;
  animation: fadeOut 1s ease-in-out;
  margin-bottom: 150px;
}
.main-page-section-title {
  width: 1125px;
  display: flex;
  justify-content: flex-start;
  font-size: 26px;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: -35px;
}

/* .main-page-section-title {
  width: 1125px;
  display: flex;
  justify-content: flex-start;
  font-size: 26px;
  font-weight: 500;
}
.main-page-section-title-wrapper {
  width: 1200px;
  height: 55px;
  padding-left: 35px;
  box-sizing: border-box;
  background-color: rgba(240, 240, 240, 0.89);
  margin-bottom: -40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */
.picture-dot-container {
  position: absolute;
  padding: 0px 120px;
  box-sizing: border-box;
  top: 340px;
  z-index: 3;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.picture-dot {
  width: 13px;
  height: 13px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #363686;
  box-sizing: border-box;
  cursor: pointer;
}
.picture-dot-disable {
  width: 13px;
  height: 13px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: gray;
  box-sizing: border-box;
  cursor: pointer;
}
.main-page-banner {
  margin-top: 50px;
  width: 100%;
  height: 367px;
  background-image: url(../../asset/image/banner-1.png);
  background-repeat: no-repeat;
  background-position: center;
  object-fit: none;

  animation: bannerFadeOut 1s ease-in-out;
}
.main-page-banner2 {
  width: 100%;
  height: 367px;
  background-image: url(../../asset/image/banner-2.png);
  background-repeat: no-repeat;
  background-position: center;
}
.main-page-banner-customer {
  width: 100%;
  padding: 0px 285px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-page-menu-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 30px 35px;
  box-sizing: border-box;
  margin: -7px 0px 50px;
  background-color: #fcfcfce6;
}
.main-page-menu-title {
  font-size: 18px;
  font-weight: 500;
  color: #363686;
  margin-bottom: 20px;
}
.main-page-menu-content {
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 20px 20px;
  box-sizing: border-box;
  height: 250px;
  background-color: #fff;
}

.main-page-menu-content-product-title-sm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}
.main-page-menu-content-product-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}
.main-page-menu-content-product-picture-box {
  width: 160px;
  height: 160px;
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-page-menu-content-product-picture-1 {
  margin-top: 10px;
  width: 100px;
  height: 100px;
  background-image: url(../../asset/image/Multi-Input-Device.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.main-page-menu-content-product-picture-2 {
  width: 140px;
  height: 140px;
  background-image: url(../../asset/image/foldingNcolumn.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.main-page-menu-content-product-picture-3 {
  width: 140px;
  height: 140px;
  background-image: url(../../asset/image/steering-wheel-remote-control-switch.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.main-page-menu-content-product-picture-4 {
  margin-top: 10px;
  width: 100px;
  height: 100px;
  background-image: url(../../asset/image/fan-motor.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.main-page-menu-content-product-container:last-child {
  margin: 0px;
}
.main-page-menu {
  margin-right: 100px;
}
.main-page-menu:last-child {
  margin: 0px;
}
.product-link-icon {
  width: 14px;
  height: 14px;
  color: #d3d3d3;
  cursor: pointer;
}
.product-link-box {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d3d3d3;
}
.main-page-menu-content-customer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
}
.main-page-menu-content-customer-content-line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #d3d3d3;
}

.main-page-menu-content-customer-content:last-child {
  border: 0px;
}
.main-page-menu-content-customer-content-logo-1 {
  width: 110px;
  height: 40px;
  background-image: url(../../asset/image/mobase-elec_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.main-page-menu-content-customer-content-logo-2 {
  width: 80px;
  height: 50px;
  margin-left: -3px;
  background-image: url(../../asset/image/denso_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.main-page-menu-content-customer-content-logo-3 {
  width: 120px;
  height: 32px;
  margin-left: -25px;
  background-image: url(../../asset/image/gm_flex-logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.main-page-menu-content-customer-content-logo-4 {
  width: 80px;
  height: 35px;
  margin-left: 2px;
  background-image: url(../../asset/image/Hitachi_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.main-page-menu-content-customer-content-logo-5 {
  width: 160px;
  height: 200px;
  margin-left: -10px;
  background-image: url(../../asset/image/lg_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.main-page-menu-content-customer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 20px;
  box-sizing: border-box;
  width: 657px;
  height: 250px;
  background-color: #fff;
}
.main-page-menu-content-customer-content-title {
  font-size: 12px;
}
.main-page-banner-picture-container {
  position: relative;
  width: 100%;
  height: 367px;
  margin-bottom: 50px;
}
.main-page-banner-picture-title {
  position: absolute;
  top: 100px;
  font-weight: 300;
  left: 120px;
  z-index: 10;
  color: #fff;
  font-size: 34px;
  letter-spacing: -1.4px;
}
.main-page-banner-picture-title-2 {
  position: absolute;
  top: 100px;
  font-weight: 300;
  left: 120px;
  z-index: 10;
  color: #fff;
  line-height: 1.2;
  font-size: 32px;
  letter-spacing: -1.4px;
}
.main-page-banner-picture-bar {
  position: absolute;
  top: 220px;
  width: 35px;
  height: 4px;
  left: 120px;
  z-index: 10;
  background-color: #363686;
}
.color-white {
  color: #fff !important;
}
.main-page-banner-picture-bar-white {
  position: absolute;
  top: 230px;
  width: 35px;
  height: 4px;
  left: 120px;
  z-index: 10;
  background-color: #fff;
}
.main-page-banner-picture-article-blue {
  color: #363686;
  letter-spacing: -1.4px;
  font-weight: 600;
}
.main-page-banner-picture-article-weight {
  color: white;
  letter-spacing: -1.4px;
  font-weight: 600;
}
.main-page-banner-picture-article-inline {
  display: flex;
}
.main-page-banner-picture-article {
  display: inline-block;
  position: absolute;
  top: 240px;
  font-weight: 400;
  left: 120px;
  z-index: 10;
  color: rgb(187, 187, 187);
  font-size: 18px;
  letter-spacing: -1.4px;
}
.main-page-banner-picture-article-2 {
  display: inline-block;
  position: absolute;
  top: 250px;
  font-weight: 400;
  left: 120px;
  z-index: 10;
  color: rgb(187, 187, 187);
  font-size: 18px;
  letter-spacing: -1.4px;
}
.color-blue {
  color: #363686;
}

.map {
  width: 710px;
  height: 500px;
}
.link-map {
  position: relative;
  right: 0px;
  top: -80px;
  font-size: 14px !important;
  font-weight: 500;
  color: black;
  text-decoration: none;
  width: 100px;
  height: 24px;
  border: 2px solid #363686;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: #363686;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  box-sizing: border-box;
}

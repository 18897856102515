.company-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.clicked {
  color: #363686;
  border-bottom: 2px solid #363686;
  box-sizing: border-box;
}
.company-sub-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 710px;
  height: 40px;
  padding: 5px 0px;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;
  margin-bottom: 50px;
  background-color: rgba(243, 242, 242, 0);
}
.company-sub-header-menu {
  font-size: 16px;
  height: 40px;
  padding: 0px 10px 5px;
  margin-right: 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.company-sub-header-menu:last-child {
  margin: 0px;
}

.company-contents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 300px;
  background-color: none;
}
/* .company-image-1{
    width:100%;
    height:900px;
    background-image: url(../../../../asset/image/vision.jpg);
background-repeat: no-repeat;
background-position: center;
}
.company-image-2{
    width:100%;
    height:2800px;
    background-image: url(../../../../asset/image/area.jpg);
background-repeat: no-repeat;
background-position: center;
} */

/*VISION*/
.company-vision-container {
  width: 710px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.company-vision-title {
  font-size: 20px;
  font-weight: 500;
  color: #363686;
  margin-bottom: 24px;
}
.company-vision-contents {
  width: 710px;
  height: 197px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  background-color: #f9f9f9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 36px;
  font-size: 16px;
  color: #4e4e4e;
  font-weight: 500;
}
.company-vision-contents-article {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #4e4e4e;
  font-weight: 500;
  line-height: 2;
}
.company-vision-contents-box {
  border-right: 1px solid #e0e0e0;
  height: 100%;
  width: 237px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.company-vision-contents-box:last-child {
  border-right: none;
}
.company-vision-icon {
  font-size: 28px;
  color: #4e4e4e;
}
.company-vision-contents-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  background-color: #ffffff;
}
.company-vision-contents-text {
  margin-top: 22px;
  font-size: 16px;
  color: #4e4e4e;
  font-weight: 500;
}
.company-certification-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.company-certification-container {
  width: 480px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.company-certification-time-container {
  width: 230px;
  height: 500px;
}
.company-certification-time-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.company-certification-time-year {
  width: 46px;
  font-size: 20px;
  color: #363686;
  font-weight: 500;
  margin-left: 20px;
  margin-right: 10px;
}
.company-certification-time-dot {
  font-size: 40px;
  color: #363686;
  font-weight: 500;
}
.company-certification-time-month {
  font-size: 13px;
  margin-top: 3px;
  color: #363686;
  font-weight: 500;
  margin-right: 10px;
}
.company-certification-time-title {
  font-size: 13px;
  margin-top: 3px;
  color: gray;
  font-weight: 500;
}

.company-certification-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 220px;
  margin-right: 20px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  margin-bottom: 40px;
}
.company-certification-no-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 290px;
  margin-right: 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
}
.company-certification-image1 {
  width: 190px;
  height: 290px;
  background-image: url(../../../../asset/image/cert1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.company-certification-image2 {
  width: 190px;
  height: 290px;
  background-image: url(../../../../asset/image/cert2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.company-certification-image3 {
  width: 190px;
  height: 290px;
  background-image: url(../../../../asset/image/cert3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.company-certification-image4 {
  width: 190px;
  height: 290px;
  background-image: url(../../../../asset/image/cert5.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.company-certification-image5 {
  width: 190px;
  height: 290px;
  background-image: url(../../../../asset/image/cert4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.company-certification-title {
  margin: 5px 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
.company-certification-date {
  margin-top: 3px;
  text-align: center;
  font-size: 14px;
}
.company-business-area-container {
  width: 710px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.company-business-area-contents {
  margin-top: 20px;
  font-size: 13px;
}
.company-business-area-title {
  font-size: 20px;
  font-weight: 500;
  color: #363686;
  margin-bottom: 24px;
}
.company-business-area-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 710px;
  background-color: white;
  height: 197px;
  border: 1px solid #f9f9f9;
}

.company-business-area-contents-dashed-line {
  margin: 20px 0px;
  border-bottom: 1px dashed #e0e0e0;
  box-sizing: border-box;
}

.company-business-area-contents-line {
  margin: 20px 0px 40px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f4f4;
}
.company-customer-container {
  width: 710px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.company-customer-country-selector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}
.company-customer-country-select {
  font-size: 14px;
  cursor: pointer;
}
.company-customer-country-select-clicked {
  font-weight: bold;
}
.company-customer-country-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  text-align: center;
  margin-left: 5px;
}

.company-customer-country-list {
  width: 170px;
  height: 62px;
  border: 1px solid #dedddd;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-right: 5px;
}
.company-customer-country-list-name {
  margin-top: 10px;
}
.company-history-container {
  width: 710px;
}
.company-history-title-line {
  width: 20px;
  height: 3px;
  background-color: #363686;
  margin-bottom: 3px;
}
.company-customer-country-container {
  position: relative;
  width: 710px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-history-title {
  font-size: 20px;
  color: #363686;
  font-weight: bold;
}
.company-customer-country-map-world {
  position: relative;
  margin-top: 100px;
  margin-left: 340px;
  min-width: 600px;
}
.company-customer-country-map-korea {
  margin-top: 40px;
  position: relative;
  width: 350px;
}
.company-customer-country-map-korea-container {
  width: 710px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-customer-world-pin-poland {
  position: relative;
  z-index: 3;
  top: -75px;
  right: 740px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeOut 1s backwards;
  animation-delay: 0.2s;
}
.company-customer-world-pin-usa {
  position: relative;
  z-index: 3;
  top: -80px;
  left: -160px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeOut 1s backwards;
  animation-delay: 0.8s;
}

.company-customer-world-pin-title {
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  background-color: #fff;
}
.company-customer-pin-icon {
  font-size: 24px;
  color: #fff;
}

.company-customer-world-pin-line-poland {
  transform: rotate(90deg);
  left: 40px;
  width: 45px;
  height: 2px;
  border-top: 2px dashed gray;
  margin-bottom: 25px;
  margin-top: 25px;
}
.company-customer-company-logo {
  width: 90px;
}
.company-customer-company-logo-lg {
  width: 180px;
}
.company-customer-world-pin-contents {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-customer-korea-pin-suwon {
  position: absolute;
  z-index: 10;
  left: 145px;
  top: 114px;
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeOut 1s backwards;
  animation-delay: 0.2s;
}
.company-customer-world-pin-line-suwon {
  transform: rotate(25deg);
  position: relative;
  right: 115px;
  width: 150px;
  height: 2px;
  border-top: 2px dashed gray;
  margin-bottom: 25px;
  margin-top: 25px;
}

.company-customer-company-logo-mobase {
  position: relative;
  left: -225px;
  top: -10px;
  width: 120px;
}
.company-customer-world-pin-contents-suwon {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes fadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.company-customer-korea-pin-changwon {
  position: absolute;
  z-index: 10;
  top: 220px;
  left: 125px;
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeOut 1s backwards;
  animation-delay: 0.8s;
}
.company-customer-world-pin-line-denso {
  transform: rotate(150deg);
  position: relative;
  left: 115px;
  top: 0px;
  width: 120px;
  height: 2px;
  border-top: 2px dashed gray;
  margin-bottom: 25px;
  margin-top: 25px;
}

.company-customer-company-logo-denso {
  position: relative;
  left: 200px;
  bottom: 0px;
  width: 100px;
}
.company-customer-world-pin-line-gm {
  transform: rotate(190deg);
  position: absolute;
  left: 262px;
  top: 240px;
  width: 90px;
  height: 2px;
  border-top: 2px dashed gray;
  margin-bottom: 25px;
  margin-top: 25px;
}

.company-customer-company-logo-gm {
  position: absolute;
  left: 355px;
  bottom: 80px;
  width: 50px;
}
.company-customer-world-pin-contents-changwon {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.history-content-container {
  position: absolute;
  z-index: 20;
  left: 400px;
  width: 1300px;
  height: 600px;
}
.history-content-container-bg {
  z-index: 10;
  width: 100%;
  height: 600px;
}
.history-content {
  position: relative;
  width: 100%;
  height: 550px;
  background-image: url(../../../../asset/image/mobase_history.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.company-business-area-image-1 {
  position: relative;
  width: 100%;
  height: 150px;
  background-image: url(../../../../asset/image/columnandkey.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.company-business-area-image-2 {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-left: 60px;
  height: 180px;
  background-image: url(../../../../asset/image/switches.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.contact-sub-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 710px;
  height: 40px;
  padding: 5px 0px;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;
  margin-bottom: 50px;
  background-color: rgba(243, 242, 242, 0);
}
.contact-sub-header-menu {
  font-size: 16px;
  height: 40px;
  padding: 0px 10px 5px;
  margin-right: 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.contact-sub-header-menu:last-child {
  margin: 0px;
}

.contact-contents {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 300px;
  background-color: none;
}
.contact-contact {
  width: 710px;
  height: 500px;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
}
.contact-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 710px;
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #f8f8f8;
}
.contact-contents-container {
  width: 710px;
  display: flex;
  flex-direction: column;
}
.contact-box-infomation {
  font-size: 15px;
  line-height: 1.8;
}
.contact-box-infomation-image {
  width: 283px;
  height: 162px;
  background-image: url(../../../../asset/image/company-pic.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
}
.contact-box-contents {
  width: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contact-box-icon {
  font-size: 15px;
  color: #363686;
  margin-right: 8px;
}
.contact-box-icon-s {
  font-size: 13px;
  color: #363686;
  margin-right: 8px;
}

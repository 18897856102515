.recruit-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.recruit-sub-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 710px;
  height: 40px;
  padding: 5px 0px;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;
  margin-bottom: 50px;
  background-color: rgba(243, 242, 242, 0);
}
.recruit-sub-header-menu {
  font-size: 16px;
  height: 40px;
  padding: 0px 10px 5px;
  margin-right: 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.recruit-sub-header-menu:last-child {
  margin: 0px;
}

.recruit-contents {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 800px;
  background-color: none;
}

.recruit-infomation {
  margin-top: 20px;
  width: 715px;
  display: flex;
  justify-content: flex-end;
}
.recruit-image-recruit1 {
  width: 100%;
  height: 250px;
  background-image: url(../../../../asset/image/notice_img1.jpg);
  background-repeat: no-repeat;
  background-position: center;
}

.recruit-image-recruit2 {
  width: 100%;
  height: 923px;
  background-image: url(../../../../asset/image/notice_img2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 50px;
}
.recruit-infomation-container {
  width: 710px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.recruit-infomation-main-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.5px;
}
.recruit-infomation-title-line {
  margin-top: 40px;
  width: 20px;
  height: 3px;
  background-color: #363686;
}
.recruit-infomation-title {
  font-size: 20px;
  color: #363686;
  font-weight: bold;
}
.recruit-link-wrapper {
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recruit-link-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 48px;
  border: 2px solid #363686;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #363686;
  font-weight: bold;
  text-decoration: none;
}
.recruit-link-btn:hover {
  cursor: pointer;
  background-color: #363686;
  color: white;
  transition: all 0.2s ease-out;
}
.recruit-infomation-table {
  width: 100%;
  margin-top: 20px;
  border-top: 2px solid #363686;
}
.recruit-infomation-table-top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.recruit-infomation-table-body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.recruit-infomation-table-top-title {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0px;
  box-sizing: border-box;
  background-color: #f1efef;
  text-align: center;
  border-bottom: 1px solid #e4e2e2;
}

.recruit-infomation-table-body-title {
  font-size: 14px;
  padding: 10px 0px;
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid #e4e2e2;
  color: black;
  text-decoration: none;
}

.recruit-infomation-table-top-1 {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}
.table-body-text {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #363686;
}
.recruit-infomation-table-top-2 {
  width: 150px;
}
.recruit-infomation-table-top-3 {
  width: 150px;
}
.recruit-infomation-flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.recruit-infomation-flex-article {
  margin-left: 100px;
  font-size: 14px;
  margin-top: 50px;
  line-height: 1.8;
}
.color-blue {
  color: #363686;
}
.recruit-devide-line {
  margin: 30px 0px;
  width: 100%;
  height: 1px;
  background-color: #f0efef;
}
.recruit-infomation-process-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.recruit-infomation-process-cicle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border: 4px solid black;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.recruit-infomation-process-icon {
  font-size: 24px;
  color: #f0efef;
}
.recruit-infomation-process-cicle-color-1 {
  border: 4px solid rgb(54, 54, 134, 0.2);
}
.recruit-infomation-process-cicle-color-2 {
  border: 4px solid rgb(54, 54, 134, 0.3);
}
.recruit-infomation-process-cicle-color-3 {
  border: 4px solid rgb(54, 54, 134, 0.4);
}
.recruit-infomation-process-cicle-color-4 {
  border: 4px solid rgb(54, 54, 134, 0.5);
}
.recruit-infomation-process-cicle-color-5 {
  border: 4px solid rgb(54, 54, 134, 0.7);
}
.recruit-infomation-process-cicle-color-6 {
  border: 4px solid rgb(54, 54, 134, 1);
  background-color: rgb(54, 54, 134, 1);
  color: #fff;
}
.recruit-talent-icon-section {
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recruit-talent-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.recruit-talent-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 112px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 10px solid rgb(255, 178, 36);
}
.recruit-talent-icon-title {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}
.recruit-talent-icon-sub-title {
  font-size: 13px;
  font-weight: 500;
  color: gray;
  letter-spacing: -0.5px;
}
.recruit-talent-icon {
  font-size: 38px;
  color: #363686;
}
.recruit-talent-description-container {
  margin-top: 60px;
  width: 710px;
  box-sizing: border-box;
  border: 1px solid #f0efef;
}
.recruit-talent-description-box {
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.recruit-talent-description-box-devide-line {
  width: 646px;
  height: 1px;
  margin: 0px auto;
  border-bottom: 1px dashed #f0efef;
  box-sizing: border-box;
}
.recruit-talent-description-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67px;
  height: 67px;
  border-radius: 50%;
  background-color: #f0efef;
}
.recruit-talent-description-title-box {
  min-width: 130px;
  margin-left: 21px;
}
.recruit-talent-description-title {
  font-size: 18px;
  font-weight: 500;
}
.recruit-talent-description-sub-title {
  font-size: 12px;
  font-weight: 500;
  color: #363686;
}
.recruit-talent-description-article {
  margin-left: 40px;
  font-size: 13px;
  color: gray;
}
.recruit-talent-description-icon {
  font-size: 28px;
  color: #fff;
}
.recruit-talent-description-article-bold {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}
.recruit-development-main-title {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.5px;
}
.recruit-walfare-main-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.5px;
}
.recruit-development-sub-title {
  margin-top: 28px;
  font-size: 14px;
  color: gray;
  font-weight: 500;
}
.recruit-development-sub-title-black {
  display: inline;
  font-size: 14px;
  color: black;
  font-weight: 500;
}
.recruit-development-detail-description-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 710px;
  padding-top: 32px;
  margin: 32px 0px;
  box-sizing: border-box;
}
@keyframes fadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.recruit-development-detail-description-circle-box {
  width: 290px;
  height: 290px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 32px solid #363686;
  animation: fadeOut 1s ease-in-out;
}
.recruit-development-detail-description-circle-box-right-top {
  position: relative;
  left: 113px;
  bottom: 32px;
  width: 145px;
  height: 145px;
  background-color: white;
  border-radius: 0px 145px 0px 0px;
  box-sizing: border-box;
  border: 32px solid rgb(255, 178, 36);
}
.recruit-development-detail-description-circle-box-left-bottom {
  position: relative;
  left: -32px;
  bottom: 32px;
  width: 145px;
  height: 145px;
  background-color: white;
  border-radius: 0px 0px 0px 145px;
  box-sizing: border-box;
  border: 32px solid rgb(255, 178, 36);
}
.recruit-development-detail-description-circle-box-center {
  position: relative;
  left: 0px;
  bottom: 290px;
  width: 226px;
  height: 226px;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
}
.recruit-development-detail-description-circle-box-small-circle-top {
  position: relative;
  left: 98px;
  bottom: 548px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #3d3dc7;
  box-sizing: border-box;
}
.recruit-development-detail-description-circle-box-small-circle-bottom {
  position: relative;
  left: 98px;
  bottom: 322px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #3d3dc7;
  box-sizing: border-box;
}
.recruit-development-detail-description-circle-box-small-circle-left {
  position: relative;
  left: -32px;
  bottom: 485px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgb(255, 142, 36);
  box-sizing: border-box;
}
.recruit-development-detail-description-circle-box-small-circle-right {
  position: relative;
  left: 226px;
  bottom: 517px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgb(255, 142, 36);
  box-sizing: border-box;
}
.recruit-development-detail-description-circle-box-small-circle-icon {
  font-size: 24px;
  color: #fff;
}
.recruit-development-detail-description-circle-box-small-circle-center-icon-box {
  position: relative;
  bottom: 631px;
  left: 13.5px;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f1f1f8;
}
.recruit-development-detail-description-circle-box-small-circle-center-icon {
  font-size: 60px;
  color: #b6b6d4;
}
.recruit-development-detail-description-side-box-left {
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: fadeOut 1s backwards;
  animation-delay: 0.5s;
}
.recruit-development-detail-description-side-box-right {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  animation: fadeOut 1s backwards;
  animation-delay: 1s;
}
.recruit-development-detail-description-side-box-left-bottom {
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: fadeOut 1s backwards;
  animation-delay: 1.5s;
}
.recruit-development-detail-description-side-box-right-bottom {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  animation: fadeOut 1s backwards;
  animation-delay: 2s;
}
.recruit-development-detail-description-side-icon {
  font-size: 36px;
  color: #b6b6d4;
}
.recruit-development-detail-description-side-title {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
}
.recruit-development-detail-description-side-article {
  margin-top: 5px;
  font-size: 12px;
  color: gray;
}
.recruit-payment-container {
  width: 100%;
}
.recruit-section-title-line {
  width: 20px;
  height: 3px;
  background-color: #363686;
  margin-bottom: 3px;
}
.recruit-section-title {
  font-size: 20px;
  color: #363686;
  font-weight: bold;
}
.recruit-payment-description-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 138px;
  border: 1px solid #f0efef;
  box-sizing: border-box;
}
.recruit-payment-description-box-item {
  padding: 20px;
  width: 50%;
  height: 138px;
  box-sizing: border-box;
  border-right: 1px solid #f0efef;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.recruit-payment-description-box-item:last-child {
  border: none;
}
.recruit-payment-description-box-item-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #b6b6d4;
  margin-right: 30px;
}
.recruit-payment-description-box-item-icon {
  font-size: 28px;
  color: #fff;
}
.recruit-payment-description-box-item-article {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.recruit-promotion-container {
  margin-top: 40px;
}
.recruit-promotion-table {
  width: 710px;
  height: 108px;
  border: 1px solid #f0efef;
  box-sizing: border-box;
  margin-top: 20px;
}
.recruit-promotion-top {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  align-items: center;
  width: 710px;
  height: 64px;
}
.recruit-promotion-bottom {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  align-items: center;
  width: 710px;
  height: 44px;
}
.recruit-promotion-table-title {
  width: 118.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background-color: #e0e0eb;
  box-sizing: border-box;
  border-right: 1px solid #f0efef;
  border-bottom: 1px solid #f0efef;
  font-size: 13px;
  font-weight: 500;
}
.recruit-promotion-table-title-column {
  width: 118.3px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 245);
  box-sizing: border-box;
  border-right: 1px solid #f0efef;
  border-bottom: 1px solid #f0efef;
  font-size: 13px;
  font-weight: 500;
}
.recruit-promotion-table-title-bottom {
  width: 118.3px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e0eb;
  box-sizing: border-box;
  border-right: 1px solid #f0efef;
  border-bottom: 1px solid #f0efef;
  font-size: 13px;
}
.recruit-promotion-table-title-column-bottom {
  width: 118.3px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
  border-right: 1px solid #f0efef;
  border-bottom: 1px solid #f0efef;
  font-size: 13px;
  font-weight: 500;
}
.recruit-walfare-container {
  width: 710px;
}
.recruit-walfare-description-box-item-title {
  position: relative;
  z-index: 3;
  font-size: 18px;
  font-weight: 500;
  color: #363686;
  text-align: center;
}

.recruit-walfare-description-box-item-article {
  position: relative;
  z-index: 3;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}
.recruit-walfare-icon-2 {
  position: absolute;
  left: 60px;
  top: 20px;
  z-index: 1;
  font-size: 60px;
  color: #36368611;
}
.recruit-walfare-icon-1 {
  position: absolute;
  left: 100px;
  top: 20px;
  z-index: 1;
  font-size: 60px;
  color: #36368611;
}
.recruit-walfare-box-item {
  position: relative;
  padding: 20px;
  width: 50%;
  height: 138px;
  box-sizing: border-box;
  border-right: 1px solid #f0efef;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recruit-walfare-box-item:last-child {
  border: none;
}
.recruit-walfare-description-box {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 1px solid #f0efef;
  box-sizing: border-box;
}
.recruit-walfare-devide-line {
  margin: 30px 0px;
  width: 100%;
  height: 1px;
  background-color: lightgray;
}
.recruit-walfare-support-container {
  width: 710px;
  box-sizing: border-box;
  margin-top: 20px;
  border-top: 1px solid lightgray;
  margin-bottom: 50px;
}
.recruit-walfare-support-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px dashed #f0efef;
}
.recruit-walfare-support-box-item {
  width: 50%;
  height: 90px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.recruit-walfare-support-box-item-icon {
  margin-right: 40px;
  font-size: 30px;
  color: gray;
}
.recruit-walfare-support-box-item-title {
  font-size: 14px;
  font-weight: 500;
}
.recruit-walfare-support-box-item-article {
  font-size: 12px;
  margin-top: 12px;
  color: #808080;
}
.recruit-walfare-devide-line {
  margin: 30px 0px 60px;
  width: 100%;
  height: 1px;
  background-color: #f0efef;
}
.recruit-health-container {
  width: 710px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.recruit-health-box {
}
.recruit-health-box-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: rgb(222, 240, 248);
}
.recruit-health-box-icon {
  font-size: 40px;
  color: #fff;
}
.recruit-health-box-title {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.recruit-health-box-article {
  margin-top: 10px;
  font-size: 12px;
  color: gray;
  text-align: center;
}

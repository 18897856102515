.rnd-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.rnd-sub-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 710px;
  height: 40px;
  padding: 5px 0px;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;
  margin-bottom: 50px;
  background-color: rgba(243, 242, 242, 0);
}
.rnd-sub-header-menu {
  font-size: 16px;
  height: 40px;
  padding: 0px 10px 5px;
  margin-right: 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.rnd-sub-header-menu:last-child {
  margin: 0px;
}

.rnd-contents {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 300px;
  background-color: none;
}
.rnd-center-container {
  width: 710px;
}
.rnd-center-title {
  font-size: 24px;
  color: #363686;
  font-weight: bold;
  margin-bottom: 50px;
  letter-spacing: -1px;
}

.rnd-center-sub-title {
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: bold;
}
.rnd-center-sub-title-line {
  width: 40px;
  height: 2px;
  background-color: #363686;
  margin: 16px 0px 32px;
}
.rnd-center-article-title {
  font-size: 16px;
  font-weight: 500;
}

.rnd-center-article {
  font-size: 14px;
}
